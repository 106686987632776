import React, { useEffect } from 'react';
import {  CToast,CToastHeader } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import { useDispatch,useSelector } from 'react-redux';
export default ({message,type})=>{
  const notification = useSelector((state) => state.notification);
  const dispatch = useDispatch();
 const [visible, setVisible] = React.useState(true);
 useEffect(() => {
  if(notification.show==false){
    return;
  }
  const timer = setTimeout(() => {
    dispatch({ type: 'setNotification', notification: { show: false, message: "", type: "" } });
  }, 5000);
  return () => clearTimeout(timer);
 },[notification.show]);
 return (
   <CToast animation={true} autohide={false} visible={notification.show} color={notification.type}>

     <CToastHeader className='bg-transparent'>
       <div className='text-white'>{notification.message}</div>
       <CIcon className='text-white' style={{ marginLeft: '8px', width: '12px' }} onClick={() => { dispatch({ type: 'setNotification', notification: { show: false, message: "", type: "" } }) }} icon={cilX} />

     </CToastHeader>

   </CToast>
 )
}