import React, { Component, Suspense, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { HashRouter, useNavigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { CSpinner } from '@coreui/react';
import { getUserInfo } from './services/auth';
import Notification from './components/Custom/Notification';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Login/Login'))
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword/ForgotPassword'))
const ForgotSent = React.lazy(() => import('./views/ForgotSent/ForgotSent'))
const ResetPassword = React.lazy(() => import('./views/ResetPassword/ResetPassword'))
const Register = React.lazy(() => import('./views/Register/Register'))
const Page404 = React.lazy(() => import('./views/Page404/Page404'))
const Page500 = React.lazy(() => import('./views/Page500/Page500'))

function App() {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  let loadingAuth=useSelector((state)=>state.loadingAuth);
  const checkAuth = async () => {
    let token = localStorage.getItem('session_sqouts');
    let res = await getUserInfo();
    dispatch({type:'setLoadingAuth',loadingAuth:true});
    try {
      if (!res.user) {
        dispatch({ type: 'logout' });
      }
      else {
        dispatch({ type: 'login', token: token });
      }
    } catch (error) {
      alert('e');
    }
    dispatch({ type: 'setLoadingAuth', loadingAuth: false });

  }
  useEffect(() => {
    // if using token from localstorage, uncomment section below
    // let token=localStorage.getItem('session_sqouts');
    // if(token){
    //   dispatch({type:'login',token:token});
    // }
    // else{
    //   dispatch({type:'logout'});
    // }
    checkAuth();
    console.log(login)
  }, [login]);
  return (
    <>
      <Notification/>
      {
        loadingAuth?
        <div className='d-flex justify-content-center w-100 align-items-center' style={{height:'100vh'}}>
            <CSpinner />
        </div>

        :
        <HashRouter>
        <Suspense fallback={loading}>
          {login ?
            <Routes>
              <Route exact path="/login" name="Login" element={<Login />} />
              <Route exact path="/404" name="Page404" element={<Page404 />} />
              <Route exact path="/500" name="Page500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
            :
            <Routes>
              <Route exact path="/login" name="Login" element={<Login />} />
              <Route exact path="/forgot_password" name="ForgotPassword" element={<ForgotPassword />} />
              <Route exact path="/reset_password" name="ResetPassword" element={<ResetPassword />} />
              <Route exact path="/forgot_sent" name="ForgotSent" element={<ForgotSent />} />
              <Route exact path="/register" name="Register" element={<Register />} />
              <Route exact path="/404" name="Page404" element={<Page404 />} />
              <Route exact path="/500" name="Page500" element={<Page500 />} />
              <Route path="*" name="alwayslogin" element={<Login />} />
            </Routes>}
        </Suspense>
      </HashRouter>}
    </>


  )
}
// class App extends Component {
//   render() {
//     return (
//       <HashRouter>
//         <Suspense fallback={loading}>
//           <Routes>
//             <Route exact path="/login" name="Login Page" element={<Login />} />
//             <Route exact path="/forgot_password" name="Forgot Password Page" element={<ForgotPassword />} />
//             <Route exact path="/reset_password" name="Reset Password Page" element={<ResetPassword />} />
//             <Route exact path="/forgot_sent" name="Forgot Password Page" element={<ForgotSent />} />
//             <Route exact path="/register" name="Register Page" element={<Register />} />
//             <Route exact path="/testing" name="Testing Page" element={<Testing />} />
//             <Route exact path="/404" name="Page 404" element={<Page404 />} />
//             <Route exact path="/500" name="Page 500" element={<Page500 />} />
//             <Route path="*" name="Home" element={<DefaultLayout />} />
//           </Routes>
//         </Suspense>
//       </HashRouter>
//     )
//   }
// }


export default App
