import appAxios from "./_axios";

//export const googleLoginUrl = `${process.env.REACT_APP_API_URL}/login`;
export const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=472253498583-b7r6qoaevjs1knfhjjdm1ubmjf8q44t0.apps.googleusercontent.com&redirect_uri=http://127.0.0.1:5000&response_type=code&scope=openid%20email%20profile`;

export const initiateLogin =  async() => {
  try {
    const response = await appAxios.get(`${process.env.REACT_APP_API_URL}/login`);
    window.location.href = response.data.redirect_uri;
  } catch (error) {
    console.error(error);
  }
  // window.location.href = `${process.env.REACT_APP_API_URL}/login`;
}

export async function getUserInfo() {
    try {
        const res = await appAxios.get(`get-user-info`)
        return res.data;
    } catch (e) {
        console.error(e);
        return {
            code: e.code,
            message: e.message
        };
    }
}
export async function RequestLogout() {
  try {
    const res = await appAxios.get(`logout`)
    return res.data;
  } catch (e) {
    console.error(e);
    return {
      code: e.code,
      message: e.message
    };
  }
}

