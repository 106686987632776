import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  loadingAuth:true,
  loadingTable:true,
  history: [],
  candidates: [],
  projects: [],
  login: false,
  askWhenLeave: false,
  token: "",
  notification: { show: false, message: "", type: "danger" }
}

const reducer = (state = initialState, { type, payload, ...rest }) => {
  switch (type) {
    case 'SET_CANDIDATES':
      return { ...state, candidates: payload }
    case 'SET_PROJECTS':
      return { ...state, projects: payload }
    case 'login':
      return { ...state, login: true, token: rest.token }
    case 'setLoadingAuth':
      return { ...state, loadingAuth: rest.loadingAuth }
    case 'setLoadingTable':
      return { ...state, loadingTable: rest.loadingTable }
    case 'set':
      return { ...state, ...rest }
    case 'history':
      return { ...state, history: state.history.filter((n) => n.id == rest.history.id).length == 0 ? [rest.history, ...state.history] : state.history }
    case 'editHistory':
      return { ...state,
        history: state.history.map((n,index) => {
          if(n.id == rest.history.id){
            n=rest.history;
          }
          return n;
        })
      }
    case 'delete':
      return { ...state, history: state.history.filter((n) => n.id != rest.id) }
    case 'login':
      return { ...state, login: true, token: rest.token }
    case 'logout':
      return { ...state, login: false, token: "" }
    case 'setNotification':
      return { ...state, notification: rest.notification }
    case 'setAskWhenLeave':
      return { ...state, askWhenLeave: rest.value }
    default:
      return state
  }
}

const myStore = createStore(reducer)
export default myStore
